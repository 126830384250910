<template>
  <div class="TimeEntry">
    <div
      class="time-entry"
      @dblclick="showEditor"
    >
      {{ format(timeEntry.startTime) }} - {{ (timeEntry.active)
        ? 'now'
        : format(timeEntry.endTime) }}
    </div>
    <TimeEntryEditor
      v-if="isEditing"
      :time-entry="timeEntry"
      :editing-time-entry="true"
      :task="task"
      @update="updateTimeEntry"
      @delete="deleteTimeEntry"
      @close-editor="closeEditor"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
  components: {
    TimeEntryEditor: () => import('./TimeEntryEditor.vue'),
  },
  props: {
    timeEntry: {
      type: Object,
      default: () => {
      },
    },
    task: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      isEditing: false,
      editingTimeEntry: false,
    };
  },
  created() {
    if (this.timeEntry.active === true) {
      this.tickTimer();
      const timerId = window.setInterval(this.tickTimer, 1000);

      this.setTimerId({ timerId });
    }
  },
  methods: {
    ...mapActions([
      'setActiveTimeEntry',
      'setTimerId',
    ]),
    tickTimer() {
      this.timeEntry.endTime = moment().toDate();
      const ms = moment.duration(this.timeEntry.getDuration()).asMilliseconds();

      document.title = moment.utc(ms).format('HH:mm:ss');
    },
    format(time) {
      return moment(time).format('HH:mm');
    },
    updateTimeEntry({ timeEntry, prevDuration, curDuration }) {
      this.timeEntry.startTime = timeEntry.startTime;
      this.timeEntry.endTime = timeEntry.endTime;

      // Do not change total, 'cause active time entry duration is not included to this.total
      if (!timeEntry.active) {
        if (prevDuration !== curDuration) {
          this.task.total -= prevDuration;
          this.task.total += curDuration;
        }
      }

      this.isEditing = false;
    },
    showEditor() {
      this.isEditing = true;
    },
    deleteTimeEntry(timeEntry) {
      this.$emit('delete', timeEntry);
    },
    closeEditor() {
      this.isEditing = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style
  lang="stylus"
  rel="stylesheet/css"
>

  .time-entry {
    cursor: pointer;
  }

</style>
